<template>

<v-container align="center" justify="center" fluid fill-height>
  <v-container v-if="loadingAutorizado">
    <v-row align='center' justify='center'>
      <v-col cols='12' sm='8' md='7'>
        <center><v-progress-circular :size="150" indeterminate :color="colores.primario"></v-progress-circular></center>
      </v-col>
    </v-row>
  </v-container>
  <!-- v-if="isAutorizado === 1" -->
  <loginLogeo  :equipo="equipo" :colores="colores"></loginLogeo>
  <loginNoautorizado v-if="isAutorizado === 2" :equipo="equipo" :colores="colores"></loginNoautorizado>
</v-container>
</template>
<script>
import loginLogeo from '@/components/login/login_logeoComponent.vue'
import loginNoautorizado from '@/components/login/login_noautorizadoComponent.vue'
import recIps from '@/js/rec_ips.js'
export default {
  name: 'Login',
  components: {
    loginLogeo,
    loginNoautorizado
  },
  mixins: [recIps],
  data: () => ({
    colores: null,
    isAutorizado: 0,
    loadingAutorizado: false,
    equipo: null,
    session: { id: 0 }
  }),
  created () {
    const rules = { requerido: v => !!v || 'Requerido', email: v => (v && /.+@.+\..+/.test(v)) || 'E-mail inválido', long5: v => (v && v.length > 5) || 'Debe ser mayor a 5 digitos', long10: v => (v && v.length >= 10) || 'Debe ser mayor a 10 digitos', igual7: v => (v.length === 0 || v.length === 7) || 'Debe ser igual a 7 digitos', igual10: v => (v.length === 0 || v.length === 10) || 'Debe ser igual a 10 digitos', combocero: v => (v && v !== 0) || 'Requerido', numerico: v => (v.length === 0 || (!isNaN(parseFloat(v)) && v >= 0)) || 'Número inválido' }
    this.$store.commit('setRules', rules)
    const colores = { primario: '#0072CE', secundario: 'black', tercero: 'white', white: 'white', orange: 'orange', black: 'black', teal: 'teal', red: 'red darken-1', green: 'green', amber: 'amber', blueGrey: 'blue-grey', grey: 'grey darken-2', brownDarken: 'brown darken-2' }
    this.$store.commit('setColores', colores)
    this.colores = this.$store.getters.getColores
    this.session = this.$store.getters.getSession
    // this.rec_ip_autorizada()
  }, // FIN DE CREATED
  watch: {
  }, // FIN DE WATCH
  methods: {

  } // FIN DE METODOS
}
</script>
