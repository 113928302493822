<template>
  <div style="width:100%">
    <v-container fluid>
        <v-card>
          <v-tabs v-model="tab" :background-color="colores.secundario" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">BANDEJA<v-icon>mdi-inbox</v-icon></v-tab>
            <v-tab href="#tab-2">CORREOS <v-badge overlap :color="colores.red" :content="(items.items_correos.correoslog.length + items.items_correos.correosotro.length)" :value="(items.items_correos.correoslog.length + items.items_correos.correosotro.length)"><v-icon>mdi-email</v-icon></v-badge></v-tab>
            <v-tab href="#tab-3">APLICATIVOS<v-icon>mdi-apps</v-icon></v-tab>
            <v-tab v-if="session.area !== 17" href="#tab-4">CERRADOS<v-icon>mdi-inbox-remove</v-icon></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" :value="'tab-1'">
              <v-card flat>
                <v-card-text>
                    <filtros :session="session" :items="items" :loadingData="loadingData" :colores="colores" :bandeja_cerrada="false" @iniciarConsulta="realizarConsulta=1" @finalizarConsulta="realizarConsulta=2"></filtros>
                    <listados :session="session" :items="items" :colores="colores"></listados>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-2'">
              <v-card flat>
                <v-card-text>
                  <correos :items="items" :colores="colores"></correos>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="3" :value="'tab-3'">
              <v-card flat><v-card-text><aplicativos :session="session" :colores="colores" :rules="rules" :items="items"></aplicativos></v-card-text></v-card>
            </v-tab-item>
            <v-tab-item :key="4" :value="'tab-4'">
              <v-card flat>
                <v-card-text>
                    <filtros :session="session" :items="items" :loadingData="loadingData" :colores="colores" :bandeja_cerrada="true"></filtros>
                   <listados_cerrados :session="session" :items="items" :colores="colores"></listados_cerrados>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-card>

    </v-container>
  </div>
</template>

<script>
// import filtros from '@/components/bandejas/filtrosComponent.vue'
// import listados from '@/components/bandejas/listadosComponent.vue'
// import correos from '@/components/bandejas/correosComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recForm from '@/js/rec_form.js'
export default {
  name: 'bandejaComponent',
  components: {
    aplicativos: () => import('@/components/aplicativos/aplicativosComponent.vue'),
    listados: () => import('@/components/bandejas/listadosComponent.vue'),
    listados_cerrados: () => import('@/components/bandejas/listados_cerradoComponent.vue'),
    filtros: () => import('@/components/bandejas/filtrosComponent.vue'),
    correos: () => import('@/components/bandejas/correosComponent.vue')
  },
  mixins: [recForm, recBandeja],
  props: ['colores', 'items', 'session', 'loadingData', 'rules'],
  data: () => ({
    tab: null,
    realizarConsulta: 1
  }),
  created () {
    if (this.session.area !== 16) {
      this.rec_bandeja_mis_pqrs_cerradas(this.session.id)
    } else {
      this.rec_bandeja_mis_pqrs_cerradas_radicador(this.session.id)
    }
    this.rec_bandeja_mis_pqrs(this.session.id)

    this.startInterval()
  },
  methods: {
    startInterval () {
      this.idSetIntervalBandeja = setInterval(() => {
        // 10000 - 10 segundos
        // 60000 - 60 segundos
        // 120000 - 2 minutos
        // 240000 - 4 minutos
        if (this.realizarConsulta === 1) {
          this.rec_bandeja_mis_pqrs(this.session.id)
          // this.rec_bandeja_correos(this.session.id)
          if (this.session.area !== 16) {
            this.rec_bandeja_mis_pqrs_cerradas(this.session.id)
          } else {
            this.rec_bandeja_mis_pqrs_cerradas_radicador(this.session.id)
          }
        }
        this.rec_bandeja_eventos()
      }, 240000)
    },
    endInterval () {
      clearTimeout(this.idSetIntervalBandeja)
    }
  }
}
</script>
