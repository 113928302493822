import axios from 'axios'
export default {
  methods: {
    rec_form_bandeja (pUsuarioId) {
      this.loadingData = true
      this.items.tiposolicitantes = []
      this.items.tipopersonas = []
      this.items.tipodocumentos = []
      this.items.tiposexos = []
      this.items.tiposi = []
      this.items.grupoespecial = []
      this.items.productos = []
      this.items.tiporequerimientos = []
      this.items.instaciarecepcion = []
      this.items.canales = []
      this.items.admisiones = []
      this.items.entecontroles = []
      this.items.motivoduplicados = []
      this.items.areas = []
      this.items.paises = []
      this.items.marcaciones = []
      this.items.tipobusquedas = []
      this.items.razonllamadas = []
      this.items.items_desistimientos = []
      this.items.items_favorabilidad = []
      this.items.items_aceptaciones = []
      this.items.items_rectificaciones = []
      this.items.items_fallotutela = []
      this.items.items_causaraizreq = []
      this.items.items_causalquejas = []
      this.items.items_marcacionessegui = []
      this.items.items_correos.correoslog = []
      this.items.items_correos.correosotro = []
      this.items.subEstados = []
      const urlroute = this.$store.getters.getUrl + 'form_bandeja/' + pUsuarioId

      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.tiposolicitantes = response.data.tiposolicitantes
        this.items.tipopersonas = response.data.tipopersonas
        this.items.tipodocumentos = response.data.tipodocumentos
        this.items.tiposexos = response.data.tiposexos
        this.items.tiposi = response.data.tiposi
        this.items.grupoespecial = response.data.grupoespecial
        this.items.productos = response.data.productos
        this.items.tiporequerimientos = response.data.tiporequerimientos
        this.items.instanciarecepcion = response.data.instanciarecepcion
        this.items.puntorecepcion = response.data.puntorecepcion
        this.items.canales = response.data.canales
        this.items.admisiones = response.data.admisiones
        this.items.entecontroles = response.data.entecontroles
        this.items.motivoduplicados = response.data.motivoduplicados
        this.items.areas = response.data.areas
        this.items.paises = response.data.paises
        this.items.departamentos = response.data.departamentos
        this.items.marcaciones = response.data.marcaciones
        this.items.tipobusquedas = response.data.tipobusquedas
        this.items.razonllamadas = response.data.razonllamadas
        this.items.items_desistimientos = response.data.desistimientos
        this.items.items_favorabilidad = response.data.favorabilidad
        this.items.items_aceptaciones = response.data.aceptaciones
        this.items.items_rectificaciones = response.data.rectificaciones
        this.items.items_fallotutela = response.data.fallotutela
        this.items.items_causaraizreq = response.data.causaraizreq
        this.items.items_causalquejas = response.data.causalquejas
        this.items.items_marcacionessegui = response.data.marcacionessegui
        this.items.items_correos = response.data.correos
        this.items.subEstados = response.data.subEstados
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loadingData = false
      })
    }

  }
}
