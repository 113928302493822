import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import IdleVue from 'idle-vue'
import Vuex from 'vuex'

Vue.config.productionTip = false

Vue.use(Vuex)

const dstore = new Vuex.Store({})
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  dstore,
  idleTime: 600000, // 10 min,
  startAtIdle: false
})

new Vue({
  router,
  store,
  dstore,
  vuetify,
  render: h => h(App)
}).$mount('#app')
