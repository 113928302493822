import axios from 'axios'
export default {
  methods: {
    rec_bandeja_eventos () {
      const urlroute = this.$store.getters.getUrl + 'eventos_jobs'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        // console.log(response.data)
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
      })
    },
    rec_bandeja_qrs (pUsuarioId) {
      const urlroute = this.$store.getters.getUrl + 'asesores_area/' + pUsuarioId
      this.items_registros = []
      this.items_registros_cerrados = []
      // this.loadingregistro = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items_registros = response.data
        if (pUsuarioId === 5) {
          for (let index = 0; index < this.items_registros.length; index++) {
            const element = this.items_registros[index]
            if (element.id_usuario !== 42568) {
              this.items_registros = []
              this.items_registros.push(element)
              break
            }
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    rec_bandeja_qrs_id (pqrCodigo) {
      const urlroute = this.$store.getters.getUrl + 'gestion_codigo/' + pqrCodigo
      // this.loadingregistro = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.items_registros = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    rec_bandeja_qrs_documento (pqrDocumento) {
      const urlroute = this.$store.getters.getUrl + 'gestion_documento/' + pqrDocumento
      // this.loadingregistro = true
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.items_registros = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    rec_bandeja_mis_pqrs (pUsuarioId) {
      const day = new Date()
      const aux = []
      day.setHours(0, 0, 0, 0)
      const today = Date.parse(day)
      if (pUsuarioId > 0) {
        const urlroute = this.$store.getters.getUrl + 'badeja_mis_pqrs/' + pUsuarioId
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.items.contSfcCase = 0
          this.items.item_registros_sin_filtro = response.data
          this.items.items_registros = this.items.item_registros_sin_filtro
          this.items.sfcCase = response.data.filter((item) => item.aplicativo.id === 4 && item.gesarchivos.length > 0)
          for (let index = 0; index < this.items.sfcCase.length; index++) {
            const element = this.items.sfcCase[index]
            for (let index2 = 0; index2 < element.gesarchivos.length; index2++) {
              const element2 = element.gesarchivos[index2]
              if (element2.reference === 5 && Date.parse(element2.created_at) >= today) {
                element2.codigo = element.codigo
                this.items.contSfcCase++
                aux.push(element2)
              }
            }
          }
          this.items.sfcCase = []
          this.items.sfcCase = aux
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_bandeja_mis_pqrs_cerradas (pUsuarioId) {
      if (pUsuarioId > 0) {
        const urlroute = this.$store.getters.getUrl + 'badeja_mis_pqrs_cerradas/' + pUsuarioId
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.items.item_registros_sin_filtro_cerrados = response.data
          this.items.items_registros_cerrados = this.items.item_registros_sin_filtro_cerrados
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_bandeja_mis_pqrs_cerradas_radicador (pUsuarioId) {
      if (pUsuarioId > 0) {
        const urlroute = this.$store.getters.getUrl + 'badeja_mis_pqrs_cerradas_radicador/' + pUsuarioId
        axios({
          url: urlroute,
          method: 'GET',
          data: null,
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.items.item_registros_sin_filtro_cerrados = response.data
          this.items.items_registros_cerrados = this.items.item_registros_sin_filtro_cerrados
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_bandeja_gestion_calidad (idUsuario) {
      if (idUsuario > 0) {
        const urlroute = this.$store.getters.getUrl + 'gestion_PQR_calidad'
        axios({
          url: urlroute,
          method: 'POST',
          data: { usuario_gest: idUsuario, anio: 0 },
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.items.items_registros = response.data
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_bandeja_mis_calificaciones (idUsuario, month) {
      if (idUsuario > 0) {
        const urlroute = this.$store.getters.getUrl + 'gestion_mis_PQR_calidad'
        axios({
          url: urlroute,
          method: 'POST',
          data: { usuario_gest: idUsuario, month: month },
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.items.item_registros_sin_filtro_cerrados = response.data
          this.items.items_registros_cerrados = this.items.item_registros_sin_filtro_cerrados
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_bandeja_correos (pUsuarioId) {
      const urlroute = this.$store.getters.getUrl + 'badeja_correos/' + pUsuarioId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.items.items_correos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    refrescarData (pUsuarioId, bandejaCerrada) {
      if (!bandejaCerrada) {
        this.items.items_registros = []
        this.items.items_registros = this.items.item_registros_sin_filtro
        this.rec_bandeja_mis_pqrs(pUsuarioId)
      } else {
        this.items.items_registros_cerrados = []
        this.items.items_registros_cerrados = this.items.item_registros_sin_filtro_cerrados
        if (this.session.area !== 16) {
          this.rec_bandeja_mis_pqrs_cerradas(pUsuarioId)
        } else {
          this.rec_bandeja_mis_pqrs_cerradas_radicador(pUsuarioId)
        }
      }
    },
    filtroData (filtro, dato, bandejaCerrada) {
      if (filtro === 1) this.filtroCodigo(dato, bandejaCerrada)
      if (filtro === 2) this.filtroProducto(dato, bandejaCerrada)
      if (filtro === 3) this.filtroOrigen(dato, bandejaCerrada)
      if (filtro === 4) this.filtroEstado(dato, bandejaCerrada)
      if (filtro === 5) this.filtroTipo(dato, bandejaCerrada)
      if (filtro === 6) this.filtroDocumento(dato, bandejaCerrada)
      if (filtro === 7) this.filtroVencido()
    },
    filtroCodigo (dato, bandejaCerrada) {
      if (!bandejaCerrada) {
        for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
          if (this.items.item_registros_sin_filtro[i].codigo === parseInt(dato, 10)) {
            this.items.items_registros = []
            this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
            break
          }
        }
      } else {
        for (let i = 0; i < this.items.item_registros_sin_filtro_cerrados.length; i++) {
          if (this.items.item_registros_sin_filtro_cerrados[i].codigo === parseInt(dato, 10)) {
            this.items.items_registros_cerrados = []
            this.items.items_registros_cerrados.push(this.items.item_registros_sin_filtro_cerrados[i])
            break
          }
        }
      }
    },
    filtroProducto (dato, bandejaCerrada) {
      if (!bandejaCerrada) {
        this.items.items_registros = []
        for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
          if (this.items.item_registros_sin_filtro[i].gesproducto) {
            if (this.items.item_registros_sin_filtro[i].gesproducto.producto.id === parseInt(dato, 10)) {
              this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
            }
          }
        }
      } else {
        this.items.items_registros_cerrados = []
        for (let i = 0; i < this.items.item_registros_sin_filtro_cerrados.length; i++) {
          if (this.items.item_registros_sin_filtro_cerrados[i].gesproducto.producto.id === parseInt(dato, 10)) {
            this.items.items_registros_cerrados.push(this.items.item_registros_sin_filtro_cerrados[i])
          }
        }
      }
    },
    filtroOrigen (dato, bandejaCerrada) {
      if (!bandejaCerrada) {
        this.items.items_registros = []
        for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
          if (this.items.item_registros_sin_filtro[i].aplicativo.id === parseInt(dato, 10)) {
            this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
          }
        }
      } else {
        this.items.items_registros_cerrados = []
        for (let i = 0; i < this.items.item_registros_sin_filtro_cerrados.length; i++) {
          if (this.items.item_registros_sin_filtro_cerrados[i].aplicativo.id === parseInt(dato, 10)) {
            this.items.items_registros_cerrados.push(this.items.item_registros_sin_filtro_cerrados[i])
          }
        }
      }
    },
    filtroEstado (dato, bandejaCerrada) {
      this.items.items_registros = []
      for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
        if (this.items.item_registros_sin_filtro[i].subestado.id === dato) {
          this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
        }
      }
    },
    filtroTipo (dato, bandejaCerrada) {
      if (!bandejaCerrada) {
        this.items.items_registros = []
        for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
          if (this.items.item_registros_sin_filtro[i].tipo.id === dato) {
            this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
          }
        }
      } else {
        this.items.items_registros_cerrados = []
        for (let i = 0; i < this.items.item_registros_sin_filtro_cerrados.length; i++) {
          if (this.items.item_registros_sin_filtro_cerrados[i].tipo.id === dato) {
            this.items.items_registros_cerrados.push(this.items.item_registros_sin_filtro_cerrados[i])
          }
        }
      }
    },
    filtroDocumento (dato, bandejaCerrada) {
      if (!bandejaCerrada) {
        this.items.items_registros = []
        for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
          if (this.items.item_registros_sin_filtro[i].gescliente !== null) {
            if (this.items.item_registros_sin_filtro[i].gescliente.identificacion === dato.toString()) {
              this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
            }
          }
        }
      } else {
        this.items.items_registros_cerrados = []
        for (let i = 0; i < this.items.item_registros_sin_filtro_cerrados.length; i++) {
          if (this.items.item_registros_sin_filtro_cerrados[i].gescliente.identificacion === dato) {
            this.items.items_registros_cerrados.push(this.items.item_registros_sin_filtro_cerrados[i])
          }
        }
      }
    },
    filtroVencido () {
      this.items.items_registros = []
      let today = new Date()
      today = today.toISOString().split('T')[0]
      for (let i = 0; i < this.items.item_registros_sin_filtro.length; i++) {
        let dayago = new Date(this.items.item_registros_sin_filtro[i].fechaparacerrarinterno)
        dayago = dayago.toISOString().split('T')[0]
        if (dayago < today) {
          this.items.items_registros.push(this.items.item_registros_sin_filtro[i])
        }
      }
    }
  }
}
