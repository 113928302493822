<template>
  <div style="width:100%">
    <menuTop :colores="colores" :session="session" :items="items"></menuTop>
    <ModalIdle v-if="isIdle" />
    <!-- <menusLeft :colores="colores"></menusLeft> -->
    <!-- <menusRight :colores="colores"></menusRight> -->
    <bandeja :loadingData="loadingData" :session="session" :colores="colores" :items="items" :rules="rules"></bandeja>
  </div>
</template>

<script>
import menuTop from '@/components/menus/menu_topComponent.vue'
import ModalIdle from '@/components/modaldle/ModalIdle'
// import menusLeft from '@/components/menus/menu_leftComponent.vue'
// import menusRight from '@/components/menus/menu_rightComponent.vue'
import bandeja from '@/components/bandejas/bandejaComponent.vue'
import recBandeja from '@/js/rec_bandeja.js'
import recForm from '@/js/rec_form.js'
export default {
  name: 'Bandeja',
  components: {
    menuTop,
    // menusLeft,
    // menusRight,
    bandeja,
    ModalIdle
  },
  mixins: [recForm, recBandeja],
  data: () => ({
    rules: null,
    loadingData: false,
    idSetIntervalBandeja: null,
    colores: null,
    session: { id: 0, nombres: '', apellidos: '', correo: '', identificacion: '', tipo: '', area: 0, permisos: [] },
    items: { tipobusquedas: [], tiposolicitantes: [], tipopersonas: [], tipodocumentos: [], tiposexos: [], tiposi: [], grupoespecial: [], productos: [], motivoprincipales: [], tiporequerimientos: [], instanciarecepcion: [], canales: [], puntorecepcion: [], admisiones: [], entecontroles: [], motivoduplicados: [], areas: [], paises: [], departamentos: [], ciudades: [], marcaciones: [], razonllamadas: [], items_registros: [], items_registros_cerrados: [], item_registros_sin_filtro: [], items_desistimientos: [], items_favorabilidad: [], items_aceptaciones: [], items_rectificaciones: [], items_fallotutela: [], items_causaraizreq: [], items_causalquejas: [], items_marcacionessegui: [], items_correos: { correoslog: [], correosotro: [] }, objMotivoQR: null, objSubMotivoQR: null, objTipoQR: null, showMarcacion: false, item_registros_sin_filtro_cerrados: [], subEstados: [], item_sfcCase: [], contSfcCase: 0 }
  }),
  computed: {
    isIdle () {
      return this.$store.state.idleVue.isIdle
    }
  },
  created () {
    this.rules = { requerido: v => !!v || 'Requerido', email: v => (v && /.+@.+\..+/.test(v)) || 'E-mail inválido', long5: v => (v && v.length > 5) || 'Debe ser mayor a 5 digitos', long10: v => (v && v.length >= 10) || 'Debe ser mayor a 10 digitos', igual7: v => (v.length === 0 || v.length === 7) || 'Debe ser igual a 7 digitos', igual10: v => (v.length === 0 || v.length === 10) || 'Debe ser igual a 10 digitos', combocero: v => (v && v !== 0) || 'Requerido', numerico: v => (v.length === 0 || (!isNaN(parseFloat(v)) && v >= 0)) || 'Número inválido', telefono: v => (v.length === 0 || ((!isNaN(parseFloat(v)) && v >= 0 && (v.length === 7 || v.length === 10)))) || 'Número inválido', alfanumerico: v => !v || /^[a-zA-Z0-9]+$/.test(v) || 'Campo alfanumérico. No caracteres especiales.', numeroGuion: v => !v || /^[0-9_-]+$/.test(v) || 'Solo números y guiones.', confirmacionEmail: v => !v || /^.+@.+\..+;$/.test(v) || 'Correo inválido' }

    const colores = { primario: '#0072CE', secundario: 'black', tercero: 'white', white: 'white', orange: 'orange', black: 'black', teal: 'teal', red: 'red darken-1', green: 'green', amber: 'amber', blueGrey: 'blue-grey', grey: 'grey darken-2', brownDarken: 'brown darken-2' }
    this.$store.commit('setColores', colores)

    this.$store.commit('setRules', this.rules)
    this.colores = this.$store.getters.getColores

    // SERVER LOCAL
    // const usu = this.$route.query.idusu
    // if (usu !== undefined) {
    //   this.session.id = this.$route.query.idusu
    // } else {
    //   this.session = this.$store.getters.getSession
    // }

    // SERVER PUBLICOs
    const s = this.$store.getters.getSession
    if (s.id > 0) {
      this.session = this.$store.getters.getSession
    }
    this.rec_form_bandeja(this.session.id)
  },
  methods: {
  }
}
</script>
