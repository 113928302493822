<template>
  <div class="overlay">
    <div class="modal">
      <div class="modal__title">
        <span>Session Expirada</span>
      </div>
      <div class="p-3">
        <p>Ha dejado este navegador inactivo durante 10 minutos.</p>
        <p>{{ time / 1000 }} segundos</p>
      </div>
    </div>
  </div>
</template>

<script>
import menuTop from '@/components/menus/menu_topComponent.vue'
export default {
  mixins: [menuTop],
  data () {
    return {
      time: 10000
    }
  },
  created () {
    const timerId = setInterval(() => {
      this.time -= 1000
      if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)

      if (this.time < 1) {
        clearInterval(timerId)
        localStorage.clear()
        this.deleteAllCookies()
        this.cerrar_session()
        window.location.replace('/')
        // document.cookie
        // Your logout function should be over here
        alert('Sesión cerrada')
      }
    }, 1000)
  },
  methods: {
    deleteAllCookies () {
      var cookies = document.cookie.split(';')
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]
        var eqPos = cookie.indexOf('=')
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  @apply bg-white p-2;
}

.modal__title {
  color: #38404f;
  @apply flex items-center justify-between p-3 font-bold;
}
</style>
